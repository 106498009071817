import { useState, ReactNode } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Typography, { TypographyProps } from '@mui/material/Typography';
import EyeOutline from 'mdi-material-ui/EyeOutline';
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BlankLayout from 'src/@core/layouts/BlankLayout';
import { getSession, signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { GetServerSidePropsContext } from 'next';

// import MenuItem from '@mui/material/MenuItem';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import ListItemText from '@mui/material/ListItemText';
// import { COUNTRIES } from 'src/lib/constants';

const TypographyStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: '0.18px',
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down('md')]: { marginTop: theme.spacing(8) }
}));

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup
    .string()
    .min(8, obj => `Password must be at least ${obj.min} characters`)
    .required()
});

const defaultValues = {
  password: '',
  email: ''
};

interface FormData {
  email: string;
  password: string;
}

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  // const onSubmit = ({ email, password }: FormData) => {
  //   auth.login({ email, password }, () => {
  //     setError('email', {
  //       type: 'manual',
  //       message: 'Email or Password is invalid'
  //     });
  //   });
  // };

  const onSubmit = async (data: FormData) => {
    const { email, password } = data;
    try {
      const res = await signIn('credentials', { email, password, redirect: false });
      if (res && res.ok) {
        router.replace(`/${router.locale}/` as string);
      } else {
        setError('email', {
          type: 'manual',
          message: 'Email or Password is invalid'
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderLoginForm = () => (
    <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <FormControl fullWidth sx={{ mb: 4 }}>
        <Controller
          name='email'
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <TextField
              autoFocus
              label='Email'
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={Boolean(errors.email)}
              placeholder=''
            />
          )}
        />
        {errors.email && (
          <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
          Password
        </InputLabel>
        <Controller
          name='password'
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <OutlinedInput
              value={value}
              onBlur={onBlur}
              label='Password'
              onChange={onChange}
              id='auth-login-v2-password'
              error={Boolean(errors.password)}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    edge='end'
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
        {errors.password && (
          <FormHelperText sx={{ color: 'error.main' }} id=''>
            {errors.password.message}
          </FormHelperText>
        )}
      </FormControl>
      <Box
        sx={{
          mb: 4,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
      ></Box>
      <Button fullWidth size='large' type='submit' variant='contained' sx={{ mb: 7 }}>
        Login
      </Button>
    </form>
  );

  return (
    <Box
      sx={{
        p: 7,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <img src='/mbb_logo_2016.svg' alt='MYBESTBRANDS' style={{ width: 450, height: 100 }} />
      <Box sx={{ mb: 6 }}>
        <TypographyStyled variant='h4'>Login</TypographyStyled>
      </Box>
      {/* {auth.user ? renderCountriesPicker() : renderLoginForm()} */}

      {renderLoginForm()}
    </Box>
  );
};

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const session = await getSession(context);

  if (session && session?.user?.userId) {
    return {
      redirect: {
        permanent: false,
        destination: `/`
      }
    };
  }

  return {
    props: {}
  };
};

LoginPage.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;

LoginPage.guestGuard = true;

export default LoginPage;
